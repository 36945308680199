#root {
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 0 1.5rem;
  height: 100vh;
  /* background: linear-gradient(#fff, #999); */
  /* background: linear-gradient(#FD297B, #FF655B); */
}

* {
  user-select: none;
}

#root>div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.app {
  overflow: hidden;
  max-width: 400px;
  max-height: 900px;
}

.app>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row>* {
  margin: 5px;
}

h1 {
  color: #fff;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}

h2 {
  color: #fff;
}

.swipe {
  position: absolute;
}

.cardContainer, .card {
  width: 350px;
  height: 540px;
}

.card {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.1);
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.swipe:last-of-type {

}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
  display: none;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 0 20px;
  display: flex;
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  border-radius: 100%;
  border: none;
  color: #fff;
  font-size: 36px;
  background-color: #111;
  transition: 200ms;
  margin: 0 20px;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.10);
  display: flex;
}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0%   { transform: scale(1,1) }
  10%  { transform: scale(1.1,1.1) }
  30%  { transform: scale(.9,.9) }
  50%  { transform: scale(1,1) }
  57%  { transform: scale(1,1) }
  64%  { transform: scale(1,1) }
  100% { transform: scale(1,1) }
}
